@import 'default';

$hookColor: #ff0000;
$red: #9e0b0f;
$blue: #2e3192;
$linghtYellow: #fffcd6;
$darken: #363636;

@font-face {
	font-family: 'segoepr';
	src: url('./fonts/segoepr/segoepr.eot'); /* IE9+ */
	src: url('./fonts/segoepr/segoepr.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/segoepr/segoepr.woff') format('woff'), /* chrome、firefox */
		 url('./fonts/segoepr/segoepr.woff2') format('woff2'), /* chrome、firefox */
		 url('./fonts/segoepr/segoepr.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
		 url('./fonts/segoepr/segoepr.svg#fontname') format('svg'); /* iOS 4.1- */
}

%none {
    display: none !important;
    visibility: hidden;
    @include opacity(0);
    font-size: 0;
}
%bgshadow {
    box-shadow: 30px 30px 0 rgba(0, 0, 0, .15);
}
%product {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include media(768px) {
        padding: {
            left: 0 !important;
            right: 0 !important;
        }
    }

    > .woocommerce-LoopProduct-link {
        position: relative;
        display: block;
        width: 100%;
        text-align: center;
        height: 250px;            
        @include media(768px) {
            height: 180px;
        }
        @include media(420px) {
            height: 220px;
        }
    }

    .wp-post-image {
        width: 60% !important;
        margin: 0 auto !important;
        padding: {
            top: 40px;
        }
    }

    .woocommerce-loop-product__title {
        width: 70%;
        margin: 0 auto !important;
        font: 14px/1.4 "segoepr" !important;
        color: black;
        @include media(768px) {
            width: 80%;
            font-size: 12px !important;
        }
    }

    .button {
        padding: 0 20px;
        border-radius: 10px;
        font: 12px 'segoepr';
    }

    .download {
        display: inline-block;
        padding: 10px 0 0;
        font-size: 0;
        vertical-align: middle;
        order: 1;

        a {
            &.woocommerce-LoopProduct-link {
                @extend %none;
            }

            display: inline-block;
            width: 15px;
            height: 20px;
            margin: {
                left: 10px;
            }
            background: url("./images/icon-download.png") center center/contain no-repeat;
            font-size: 0;
            text-indent: 899px;
        }
    }
}

// u
.u-title {
    position: absolute;
    right: 0;
    transform: translateY(-120%);
    text-align: left !important;
    font: 26px 'segoepr', Arial;

    @each $l in right, left {
        &.z-#{$l} {

            @if $l == left {

                #{$l}: 10%;

                &:after {
                    transform: rotate(90deg)
                            translate(-80%, 30%);
                }

                &.wpb_start_animation:after {
                    transform: rotate(0) 
                    translate(-80%, 30%);
                }

            } @else {

                #{$l}: 0;

                &:after {
                    transform: rotate(-90deg)
                            translate(90%, 50%);
                }

                &.wpb_start_animation:after {
                    transform: rotate(0) 
                    translate(90%, 50%);
                }
            }

            &:after {
                #{$l}: 0;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        @include opacity(0);
    }

    &.wpb_start_animation {
        &:after {
            @include opacity(1);
            transition: transform .3s linear .3s,
            opacity .3s linear .3s;
        }
    }

    @each $i, $w, $h, $ti in (blue, 220px, 58px, 70px),
                    (pink, 210px, 60px, 40px),
                    (brown, 229px, 74px, 50px) {
        
        &.s-#{$i} {
            @if $i == brown {
                top: 30px;
            } @else {
                top: 0;
            }

            width: $w;
            height: $h;
            line-height: $h;
            text-indent: $ti;
            @if $i == blue {
                background: url("./images/title-#{$i}.jpg") center center/contain no-repeat;
            } @else {
                background: url("./images/title-#{$i}.png") center center/contain no-repeat;
            }

            &:after {
                width: 59px;
                @if $i == brown {
                    height: 125px;
                } @else {
                    height: 85px;
                }
                @if $i == blue {
                    background: url("./images/title-#{$i}2.jpg") center center/contain no-repeat;
                } @else {
                    background: url("./images/title-#{$i}2.png") center center/contain no-repeat;
                }
            }
        }
    }
}
.u-title2 {
    padding: 20px 0;
    background: url("./images/artline stroke.png") left center/contain no-repeat;
    color: $red !important;
    font: bold 16px 'segoepr' !important;
    text-indent: 60px;

    @each $i in 6, 8 {
        &.f-ti#{$i} {
            text-indent: #{$i}0px;
        }
    }

    &.size2 {
        background-size: 80% 100%;
        @include media(768px) {
            background-size: 100% 100%;
            text-indent: 46px;
            font-size: 14px !important;
        }
        @include media(420px) {
            text-indent: 80px;
        }
    }
}
.u-title3 {
    position: relative;
    text-align: center !important;
    font: 18px 'segoepr';

    @each $i in pink, brown {

        &.s-#{$i} {
            background: url("./images/title-dark #{$i}.png") center center/contain no-repeat;
            @if $i == pink {
                line-height: 2.5;
            } @else {
                line-height: 3;
            }

            &:before {
                position: absolute;
                top: 50%;
                content: '';
                width: 42px;
                height: 103px;
                background: url('./images/title-dark #{$i}2.png') center center/contain no-repeat;
            }
        }
    }

    @each $l in left, right {
        &.z-#{$l} {
            &:before {
                #{$l}: 0;
            }
        }
    }
}
.u-btn {
    margin: {
        top: 20px;
    }

    a {
        padding: 0 20px !important;
        background-color: $red !important;
        border: 1px solid $red !important;
        border-radius: 30px !important;
        font: 12px/1.2 'segoepr', Arial;
        color: white !important;

        &:hover {
            background-color: transparent !important;
            transition: background-color .3s linear;
            color: $red !important;
        }
    }
}

// f

@each $x in 7, 8 {

    .f-width#{$x} {
        width: #{$x}0%;
        margin: 0 auto;
        @include media(420px) {
            @if $x == 7 {
                width: 80%;
            }
        }
    }
}
.f-relative {
    position: relative;
}
.f-shadow {
    .wpb_content_element {
        
        @extend %bgshadow;
    }
}



.header-clone {
    @extend %none;
}
.site-header {
    position: static !important;
    padding: {
        top: 40px !important;
        bottom: 0 !important;
    }
    background-color: transparent !important;
    @include media(768px) {
        padding: {
            top: 10px !important;
        }
    }

    .row {
        background-color: white;
        @extend %bgshadow;
    }

    .btn-menu {
        line-height: 1;
        color: $red;
    }
}
.header-wrap .col-md-4 {
    position: relative;
    margin: {
        bottom: 10px;
    }
    padding: {
        top: 40px;
        bottom: 10px;
    }
    @include media(768px) {
        padding: {
            bottom: 0;
        }
    }

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 80%;
        height: 1px;
        background-color: black;
        transform: translateX(-50%);
    }
}

#mainnav ul li {
    position: relative;
    padding: 0 70px;

    &.current-menu-item,
    &.current_page_item,
    &:hover {
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 35px;
            transform: translateY(-50%);
            width: 25px;
            height: 20px;
            background: url("./images/hook.png") center center/contain no-repeat;
        }
    }

    a {
        font: 18px 'segoepr', Arial, Helvetica, sans-serif;
    }
}

.site-footer {
    padding: {
        top: 10px;
    }
    background-color: transparent;
    text-align: center;

    p {
        display: inline-block;
        padding: 0 10px;
        background-color: rgba($color: white, $alpha: .6);
        font: 14px "segoepr";
        color: $darken;
    }
}
.widget-area {
    padding: 40px 0 0;
    background-color: transparent !important;

    .menu {
        margin: {
            top: 30px;
            bottom: 0;
        }
        border: {
            top: 2px solid $darken;
        }
        text-align: right;
        @include media(768px) {
            text-align: center;
        }

        li {
            display: inline-block;
            
            + li {
                margin: {
                    left: 50px;
                }
                @include media(420px) {
                    margin: {
                        left: 10px;
                    }
                }
            }

            &:before {
                content: '';
                float: left;
                width: 8px;
                height: 8px;
                margin: {
                    top: 9px;
                    right: 6px;
                }
                border-radius: 50%;
                background-color: $darken;
            }

            a {
                font: 16px/1 'segoepr';
                color: $darken;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    .widget {
        margin: {
            bottom: 0;
        }
    }
}

.about {
    position: relative;
    margin: {
        left: 0;
        right: 0;
    }
    background-color: white;
    @extend %bgshadow;
    @include media(420px) {
        padding: {
            top: 40px;
        }
    }

    li {
        line-height: 1.2;

        + li {
            margin: {
                top: 20px;
            }
        }
    }
}

.products-slider {
    position: relative;

    > .vc_row {
        padding: {
            top: 20px;
        }
        margin: {
            left: 0;
            right: 0;
        }
        @extend %bgshadow;

        > .wpb_column > .vc_column-inner {
            padding: {
                top: 80px;
            }
        }
    }

    .ywcps-nav {
        .ywcps-nav-prev,
        .ywcps-nav-next {
            @include opacity(1);
        }
        
        .ywcps-nav-prev {
            left: -50px !important;
        }

        .ywcps-nav-next {
            right: -50px !important;
        }

        @each $i in prev, next {
            #default_#{$i} {
                width: 30px !important;
                border: none !important;
                background: url("./images/icon-#{$i}.png") center center/contain no-repeat !important;
            }
        }
    }

    @include media(768px) {
        .products {
            padding: {
                bottom: 0 !important;
            }
        }
    }

    .note {
        width: 60%;
        margin: 0 auto;
        padding: 20px 0;
        @include media(420px) {
            width: 80%;
        }

        span {
            display: block;
            font-size: 34px;
            @include media(768px) {
                font-size: 24px;
            }
            @include media(420px) {
                font-size: 18px;
            }
            
            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}
.owl-item {
    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            .product > .woocommerce-LoopProduct-link {
                @if $i % 3 == 1 {
                    background: url("./images/paper1.png") center top/contain no-repeat;
                    background-position: center 20px;

                } @else if $i % 3 == 2 {
                    
                    background: url("./images/paper2.png") center top/contain no-repeat;
                } @else if $i % 3 == 0 {
                    
                    background: url("./images/paper3.png") center top/contain no-repeat;
                }
            }
        }
    }

    .product {
        @extend %product;
    }
}

.contact {
    position: relative;

    > .vc_row {
        padding: {
            top: 50px;
            bottom: 30px;
        }
        margin: {
            left: 0;
            right: 0;
        }
        @extend %bgshadow;
    }

    .email {
        p {
            font-family: "segoepr", Arial, Helvetica, sans-serif;
            font-weight: bold;
            @include media(420px) {
                padding: {
                    top: 30px;
                }
            }

            &:before {
                content: '';
                float: left;
                width: 30px;
                height: 28px;
                margin: {
                    right: 10px;
                }
                background: url("./images/icon-email.png") center center/contain no-repeat;
            }
        }
    }

    .email,
    .wpcf7 {
        width: 80%;
        margin: {
            left: auto;
        }
        @include media(420px) {
            width: 100%;
        }
    }

    .wpcf7 {
        padding: {
            top: 10px;
        }

        span {
            position: relative;
        }
        
        .wpcf7-not-valid-tip {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }

        label {
            margin: {
                bottom: 15px;
            }
            vertical-align: top;
            font-family: 'segoepr', Arial;

            &:nth-child(3),
            &:nth-child(7) {

                padding: {
                    left: 10px;
                }
                @include media(768px) {
                    padding: {
                        left: 0;
                    }
                }
            }

            span {
                display: inline-block;
                width: 10px;
                height: 1px;
            }

            @each $i in 1, 5, 7 {

                &:nth-child(#{$i}) {
                    span {
                        @if $i == 1 {
                            width: 26px;
                            @include media(768px) {
                                width: 29px;
                            }
                        } @else if $i == 5 {
                            width: 24px;
                            @include media(768px) {
                                width: 25px;
                            }
                        } @else if $i == 7 {

                            width: 28px;
                            @include media(768px) {
                                width: 46px;
                            }
                        }
                    }
                }
            }

            @include media(768px) {

                &:nth-child(3) {
                    span {
                        width: 29px;
                    }
                }
            }
        }

        %input {
            margin: {
                left: 10px;
            }
            border-radius: 10px;
            border: 1px solid #666;
            background-color: #fffcd6;
            font-size: 14px;

            &:focus {
                outline: 0;
            }
        }
        input {

            &[type="text"],
            &[type="email"],
            &[type="tel"],
            &[type="tel"] {

                @extend %input;
                width: 38%;
                height: 30px;
                @include media(768px) {
                    width: 79%;
                }
                @include media(420px) {
                    width: calc(100% - 91px);
                }
            }
        }

        $w: 90px; // textarea label's width
        textarea {
            @extend %input;
            resize: none;
            width: calc(100% - #{$w});
            @include media(420px) {
                height: 80px;
            }
        }
    }

    .btn-wrap {
        text-align: right;

        input {
            padding: 0 15px;
            border-radius: 8px;
            line-height: 1.2;
            font-family: 'segoepr';
            text-transform: lowercase;

            &[type="reset"] {
                margin: {
                    right: 10px;
                }
                color: $red;
                background-color: white !important;

                &:hover {
                    background-color: transparent !important;
                }
            }
        }
    }
}
.contact-address {
    position: relative;

    > .vc_row {
        margin: {
            left: 0;
            right: 0;
        }
        padding: 50px 0;
        background-color: white;
        @extend %bgshadow;
    }

    .item {
        + .item {
            margin: {
                top: 40px;
            }
        }

        @include media(420px) {
            .vc_col-sm-6 + .vc_col-sm-6 {
                padding: {
                    top: 20px;
                }
            }
        }
    }

    .list {
        padding: {
            top: 10px;
            left: 0;
        }
        list-style: none;

        li {
            position: relative;
            padding: {
                left: 30px;
            }
            list-style: none;
            font: 16px/1.2 'segoepr';

            + li {
                margin: {
                    top: 20px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 22px;
                height: 22px;
                vertical-align: top;
            }

            @each $i, $img in (1, location), (2, tel), (3, fax) {
                &:nth-child(#{$i}) {
                    &:before {
                        background: url("./images/icon-#{$img}.png") center center/contain no-repeat;
                    }
                }
            }
        }
    }
}

.content-area .hentry {
    position: static;
}
.adornment {
    .vc_col-sm-12 {
        position: static;
    }

    .pin,
    .leaf,
    .leaf2,
    .pen,
    .tap,
    .pen2,
    .pen3,
    .pencil {
        position: absolute;
    }

    .pin {
        width: 8%;
        top: 3%;
        @include media(768px) {
            display: none;
        }

        &.left {
            left: 15.5%;
            @include media(1600px) {
                left: 10%;
            }
            @include media(1440px) {
                left: 5%;
            }
            @include media(1366px) {
                left: 3%;
            }
            @include media(1280px) {
                left: 0;
            }

            img {
                transform: rotate(45deg);
            }
        }
        &.right {
            right: 15.5%;
            @include media(1600px) {
                right: 10%;
            }
            @include media(1440px) {
                right: 5%;
            }
            @include media(1366px) {
                right: 3%;
            }
            @include media(1280px) {
                right: 0;
            }

            img {
                transform: rotate(-45deg);
            }
        }
    }

    .leaf {
        width: 35%;
        top: 8%;
        right: -5%;
        @include media(1366px) {
            right: -12%;
        }
        @include media(420px) {
            top: 4%;
        }
        
        img {
            transform: rotateY(180deg) rotateZ(5deg);
        }
    }

    .pen {
        width: 30%;
        top: 22%;
        left: -5%;
        @include media(1600px) {
            left: -15%;
        }
        @include media(1440px) {
            left: -20%;
        }
        @include media(1280px) {
            top: 24%;
            left: -25%;
        }
        @include media(768px) {
            top: 20%;
            left: -20%;
        }
    }

    .tap {
        width: 6%;
        top: 38%;
        left: 16.5%;
        @include media(1600px) {
            left: 10%;
        }
        @include media(1440px) {
            left: 6%;
        }
        @include media(1366px) {
            left: 3.5%;
        }
        @include media(1280px) {
            left: 1%;
        }
        @include media(768px) {
            top: 34%;
        }
    }

    .pen2 {
        width: 20%;
        top: 45%;
        right: -5%;
        @include media(1440px) {
            right: -10%;
        }
        @include media(768px) {
            top: 40%;
        }
        @include media(420px) {
            top: 38%;
        }
    }

    .leaf2 {
        width: 22%;
        top: 46%;
        left: 0;
        @include media(1440px) {
            top: 52%;
        }
        @include media(1280px) {
            top: 55%;
        }
        @include media(768px) {
            top: 50%;
        }
    }

    .pen3 {
        width: 25%;
        top: 70%;
        left: -7%;
        @include media(1440px) {
            top: 71%;
        }
        @include media(1280px) {
            top: 73%;
            left: -15%;
        }
        @include media(768px) {
            top: 64%;
        }
        @include media(420px) {
            top: 56%;
        }
    }

    .pencil {
        width: 20%;
        top: 80%;
        right: -2%;
        @include media(1440px) {
            top: 84%;
        }
        @include media(1366px) {
            right: -4%;
        }
        @include media(768px) {
            top: 74%;
            right: -10%;
        }
        @include media(420px) {
            top: 88%;
        }
    }
}


// history
.about-content {
    padding: {
        bottom: 50px;
    }
}
.mission-aspiration {
    > .vc_row {
        margin: {
            left: 0;
            right: 0;
        }
        @extend %bgshadow;
    }

    .left {
        float: right;
        padding: {
            bottom: 54px - 45px;
        }

        p {
            text-align: right;
        }

        .wpb_single_image {
            text-align: left;
        }

        td {
            text-align: right;

            &:last-child {
                width: 120px;
            }
        }
    }

    .right {
        td {
            text-align: left;

            &:first-child {
                width: 120px;
            }

            .icon {
                margin: {
                    left: auto;
                }
            }
        }
    }

    .wpb_content_element + .wpb_content_element {
        margin: {
            top: 20px;
        }
    }

    .vc_custom_1526868104911,
    .vc_custom_1526868119290 {
        padding: 60px 0 30px;
    }

    table {
        margin: {
            top: 40px;
        }

        th,
        td {
            border: none;
        }
    
        td {
            line-height: 1.2;
            color: black;
        }

        tr + tr {
            td {
                padding: {
                    top: 20px;
                }
            }
        }
    }


    .icon {
        width: 81px;
        height: 63px;

        @each $i in 1, 2, bag, car, tags, sc {
            &.icon-#{$i} {
                @if $i == 2 {

                    background: url("./images/icon-#{$i}.png") center center/contain no-repeat;
                } @else {

                    background: url("./images/icon-#{$i}.png") center center no-repeat;
                }
            }
        }
    }
}
.network {
    > .vc_row {
        margin: {
            left: 0;
            right: 0;
        }
        padding: 100px 0 70px;
        @extend %bgshadow;
    }

    .vc_row + .vc_row {
        padding: {
            top: 50px;
        }
        @include media(420px) {
            padding: {
                top: 0;
            }
        }
    }

    @include media(420px) {
        .wpb_single_image {
            padding: {
                top: 30px;
            }
        }
    }

    .title {
        left: 18%;
        font-size: 18px;
    }

    img {
        height: 51px;
        width: auto;
    }

    .logo {
        img {
            width: 80%;
            height: auto;
        }
    }

    p {
        text-align: center;
    }
}
.history-ad {
    .pen2 {
        top: 28%;
        @include media(1600px) {
            top: 34%;
        }
        @include media(420px) {
            top: 26%;
        }
    }

    .his-leaf,
    .his-pencil {
        position: absolute;
    }

    .his-leaf {
        left: -15%;
        top: 44%;
        width: 40%;
        transform: rotateX(180deg) rotateZ(-60deg);
        @include media(768px) {
            top: 30%;
        }
    }

    .his-pencil {
        left: -5%;
        top: 70%;
        width: 20%;
        @include media(1024px) {
            top: 65%;
            left: -16%;
        }
        @include media(768px) {
            left: -20%;
            width: 40%;
        }

        img {
            transform: rotate(180deg);
        }
    }
}

// contact
.contact-ad {
    .leaf {
        top: 40%;
        right: 75%;
        @include media(420px) {
            top: 35%;
        }

        img {
            transform: rotateY(0);
        }
    }
    
    .pen3 {
        top: 59%;
    }

    .pencil {
        top: 80%;
        @include media(1024px) {
            right: -15%;
        }
    }
}

// products
.products-wrap {
    position: relative;

    > .vc_row {
        padding: {
            bottom: 31px;
        }
        margin: {
            left: 0;
            right: 0;
        }
        background: url("./images/bg-2.jpg") left top/100% auto repeat-y;
        @extend %bgshadow;
        @include media(420px) {
            padding: {
                top: 80px;
            }
        }

        &:after {
            $h: 40px;
            width: 100%;
            left: 0;
            bottom: 0;
            width: 100%;
            height: $h;
            margin: {
                bottom: -$h - 5px;
            }
            background-color: white;
        }
    }
    
    .title {
        top: -80px;

        &:after {
            top: 0;
            left: 0;
            transform: rotate(60deg) translate(-100%, 100%) !important;
            @include media(420px) {
                transform: rotate(60deg) translate(-60%, 60%) !important;
            }
        }
    }

    .products {
        margin: {
            bottom: 13px !important;
        }

        .product {
            @for $i from 1 through 100 {
                &:nth-child(#{$i}) {
                     > .woocommerce-LoopProduct-link {
                        @if $i % 4 == 1 {
                            background: url("./images/paper1.png") center top/contain no-repeat;
                            background-position: center 20px;
        
                        } @else if $i % 4 == 2 {
                            
                            background: url("./images/paper2.png") center top/contain no-repeat;
                        } @else if $i % 4 == 3 {
                            
                            background: url("./images/paper3.png") center top/contain no-repeat;
                        } @else if $i % 4 == 0 {
                            
                            background: url("./images/paper2.png") center top/contain no-repeat;
                        }
                    }
                }
            }
            @extend %product;

            &.post-213 {
                > .woocommerce-LoopProduct-link {
                    height: 290px;
                    background: url('./images/paper4.png') center top/contain no-repeat;

                    img {
                        padding: {
                            top: 20px;
                        }
                    }

                    .woocommerce-loop-product__title {
                        width: 65%;
                    }
                }
            }
        }
    }
}
.products-ad {
    .leaf2 {
        top: 75%;
        left: 85%;
        @include media(420px) {
            top: 80%;
        }

        img {
            transform: rotateY(180deg) rotateZ(-60deg);
        }
    }
}

// product content
.picSlider {
    width: 80%;
    margin: {
        left: auto;
        right: auto;
    }

    img {
        width: 100%;
        cursor: pointer;
    }

    .icon-prev,
    .icon-next {
        &:before {
            color: white;
        }
    }
}
.product-ad {
    .pin {
        top: 8%;
    }
}
.single-product {
    #main {
        float: left;
        width: 100%;
        @extend %bgshadow;
    }
    #primary {
        position: static;
    }

    .product {
        position: static !important;
    }
}
.carousel-img {
    padding: {
        bottom: 20px;
    }

    img {
        width: auto;
    }
}
